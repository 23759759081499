<template>
  <div>
    <TestComponent ref="component" :objType="{one: 'tab', multi: 'tabs'}" :edit-not-submittable="editNotSubmittable" :create-not-submittable="createNotSubmittable"
    @delete-submit="submitDeleteModal"
    @edit-submit="submitEditModal"
    @create-submit="submitCreateModal" @edit-show="showEditModal" @create-show="showCreateModal"
    tableTitle="Manage Navbar"
    :data="navTabs"
    nameField="name"
    :formFields='[
      {name: "name", type: "text", required: true, label: "Name"},
      {name: "url", type: "text", required: true, label: "URL", placeholder:"/settings/profile"},
      {name: "icon", type: "text", required: true, label: "Icon", placeholder:"icon-star"}
    ]'
    :orderBy="{column: 'name', ascending: true}"
    :columns="['name', 'url', 'icon', 'edit']"
    :headings="{name: 'Tab Name', url: 'Tab URL', icon: 'Tab Icon', edit: 'Edit'}"
    :sortable="['name', 'url', 'icon']"
    :filterable="['name', 'url', 'icon']">

      <div slot= "createModalInput">
        <label class="form-check-label w-100 d-flex justify-content-center">
            <fa-icon :icon="['fas', 'circle-plus']" @click="createChildTabs.push({name:'', url:'', icon:''})"/>
            <span style="margin-left: 10px; line-height: 1">Add Child Tab</span>
        </label>
        <br>
        <b-card v-for="(tab, index) in createChildTabs" :key="index">
          <p slot="header" class="form-check-label ">Child {{index + 1}}<i style="float:right;" class="cui-circle-x icons font-lg d-block" @click="createChildTabs.splice(index,1)"></i></p>
          <label class="form-check-label">Name</label>
          <input @keyup.enter="createEnterButton()"  :class="{ 'border-danger': !tab.name}" class="form-control" placeholder="Name" v-model="tab.name" >
          <br>
          <label class="form-check-label">URL</label>
          <input @keyup.enter="createEnterButton()" placeholder="/settings/profile" :class="{ 'border-danger': !tab.url}" class="form-control" v-model="tab.url" >
          <br>
          <label class="form-check-label">Icon</label>
          <input @keyup.enter="createEnterButton()" placeholder="icon-star" :class="{ 'border-danger': !tab.icon}" class="form-control" v-model="tab.icon" >
        </b-card>

      </div>
      <div slot= "editModalInput">
          <label class="form-check-label w-100 d-flex justify-content-center">
              <fa-icon :icon="['fas', 'circle-plus']" @click="editChildTabs.push({name:'', url:'', icon:''})"/>
              <span style="margin-left: 10px; line-height: 1">Add Child Tab</span>
          </label>
        <br>
        <b-card v-for="(tab, index) in editChildTabs" :key="index">
          <p slot="header" class="form-check-label ">Child {{index + 1}}<i style="float:right;" class="cui-circle-x icons font-lg d-block" @click="editChildTabs.splice(index,1)"></i></p>
          <label class="form-check-label">Name</label>
          <input @keyup.enter="editEnterButton" class="form-control" :class="{ 'border-danger': !tab.name}" placeholder="Name" v-model="tab.name" >
          <br>
          <label class="form-check-label">URL</label>
          <input @keyup.enter="editEnterButton" class="form-control" :class="{ 'border-danger': !tab.url}" placeholder="/settings/profile" v-model="tab.url" >
          <br>
          <label class="form-check-label">Icon</label>
          <input @keyup.enter="editEnterButton" class="form-control" :class="{ 'border-danger': !tab.icon}" placeholder="icon-star" v-model="tab.icon" >
        </b-card>

      </div>
    </TestComponent>
  </div>
</template>

<script>
  import fcr from '../../../services/fcr.js'
  import TestComponent from '../Component.vue'

  export default {
    components: {
      TestComponent,
    },
    data: () => {
      return {
        navTabs: [],
        createChildTabs: [],
        editChildTabs: [],
        errorMsg: {method: "", status:"", statusText: ""},
      }
    },
    beforeCreate() {
      fcr.getAllNavOptions().then(response => {
        this.navTabs = response.data;
      }).catch(error => {
        this.$mToast({
          title: error.response.status + ' Error',
          text: "Sidebar Tabs couldn't be retrieved: " + error.response.statusText,
          style: 'error'
        });
      })
    },
    methods: {
      submitEditModal(tab) {
        tab.children = this.editChildTabs;
        fcr.editNavItem(tab).then(() => {
          location.reload();
        }).catch(error => {
          this.$mToast({
            title: error.response.status + ' Error',
            text: "Tab couldn't be edited: " + error.response.statusText,
            style: 'error'
          });
        })
      },
      submitDeleteModal(id, reload=true) {
        fcr.deleteNavItem(id).then(() => {
          if(reload){
            location.reload();
          }
        }).catch(error => {
          this.$mToast({
            title: error.response.status + ' Error',
            text: "Tab couldn't be deleted: " + error.response.statusText,
            style: 'error'
          });
        })
      },
      submitCreateModal(tab) {
        tab.children = this.createChildTabs;
        fcr.createNavItem(tab).then(() => {
          this.$mToast({
            title: 'Success',
            text: "Tab created",
            style: 'success'
          });
          fcr.getAllNavOptions().then(response => {
            this.navTabs = response.data;
          }).catch(error => {
            this.$mToast({
              title: error.response.status + ' Error',
              text: "Sidebar Tabs couldn't be retrieved: " + error.response.statusText,
              style: 'error'
            });
          })
        }).catch(error => {
          this.$mToast({
            title: error.response.status + ' Error',
            text: "Tab couldn't be created: " + error.response.statusText,
            style: 'error'
          });
        })
      },
      showCreateModal() {
        this.createChildTabs = [];
      },
      showEditModal(tab) {
        this.editChildTabs  = tab.children;
      },
      createEnterButton() {
        this.$refs.component.createEnterButton()
      },
      editEnterButton()  {
        this.$refs.component.editEnterButton()
      }
    },
    computed: {
      editNotSubmittable(){
        for(let child of this.editChildTabs){
          for(let field in child){
            if(!child[field]){
              return true;
            }
          }
        }
        return false;
      },
      createNotSubmittable(){
        for(let child of this.createChildTabs){
          for(let field in child){
            if(child[field] ==="" || child[field]===undefined){
              return true;
            }
          }
        }
        return false;
      },
    }
  }
</script>

<style scoped>

</style>